import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isWindowView, scrollToTop } from "../../../helpers";
import { userState } from "../../../storage/auth";
import { sidebarState, sideMenuState } from "../../../storage/pages";
import Akademik from "./Akademik";
import Aktivitas from "./Aktivitas";
import Fo from "./Fo";
import HumasPemasaran from "./HumasPemasaran";
import It from "./It";
import Kacab from "./Kacab";
import Pjm from "./Pjm";
import Pkk from "./Pkk";
import Ksu from "./Ksu";

export const SinggleMenu = (props) => {
  const { newLabel, updateLabel, title, link, fa } = props;
  const setSideMenu = useSetRecoilState(sideMenuState);
  const setSidebar = useSetRecoilState(sidebarState);

  const setMenus = () => {
    setSideMenu(null);
    if (isWindowView()) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  };

  return (
    <li className="nav-item">
      <Link
        className="nav-link position-relative ps-3 pe-4 py-2"
        to={link}
        onClick={() => {
          setMenus();
          scrollToTop();
        }}
      >
        <i className={`fa fa-fw ${fa} me-1`} />
        <span className="text-sm">{title}</span>
        {newLabel && <span className="position-absolute me-2 end-0 bg-danger rounded text-sm px-1 text-white" style={{top: '14%'}}>Baru</span>}
        {updateLabel && <span className="position-absolute me-2 end-0 bg-warning rounded text-sm fw-semibold px-1 text-black" style={{top: '14%'}}>Update</span>}
      </Link>
    </li>
  );
};

export const MultipleMenu = (props) => {
  const { newLabel, updateLabel, title, fa, links } = props;
  const [sideMenu, setSideMenu] = useRecoilState(sideMenuState);
  const setSidebar = useSetRecoilState(sidebarState);

  const setMenus = () => {
    sideMenu === title ? setSideMenu(null) : setSideMenu(title);
  };

  return (
    <li className="nav-item">
      <Link
        to="#"
        className="nav-link px-3 py-2 collapsed"
        data-toggle="collapse"
        onClick={() => {
          setMenus();
        }}
      >
        <i className={`fas fa-fw ${fa}`} />
        <span className="text-sm" style={{ fontSize: "13px" }}>{title}</span>
        {newLabel && <span className="position-absolute me-2 end-0 bg-danger rounded text-sm px-1 text-white" style={{top: '14%'}}>Baru</span>}
        {updateLabel && <span className="position-absolute me-2 end-0 bg-warning rounded text-sm fw-semibold px-1 text-black" style={{top: '14%'}}>Update</span>}
      </Link>
      <div className={`collapse ${sideMenu === title ? "show" : "hide"}`}>
        <div className="bg-white py-2 collapse-inner rounded">
          {links &&
            links.length > 0 &&
            links.map((i, index) => {
              if (i.header) {
                return (
                  <h6 className="collapse-header text-primary" key={index}>
                    {i.header} :
                  </h6>
                );
              } else {
                return (
                  <Link
                    key={index}
                    className="collapse-item"
                    to={i.link}
                    onClick={() => {
                      scrollToTop();
                      setSideMenu(null);
                      if (isWindowView()) {
                        setSidebar(true);
                      } else {
                        setSidebar(false);
                      }
                    }}
                  >
                    {i.title}
                  </Link>
                );
              }
            })}
        </div>
      </div>
    </li>
  );
};

const DashboardSidebar = () => {
  const user = useRecoilValue(userState);
  const sidebar = useRecoilValue(sidebarState);

  return (
    <>
      <ul
        className={`navbar-nav bg-gradient-primary shadow-lg rounded-end-4 sidebar sidebar-dark accordion me-lg-5 ${
          sidebar ? "" : "toggled"
        }`}
        id="accordionSidebar"
      >
        <Link to='/dashboard' className="position-sticky top-0 sidebar-brand bg-gradient-primary border-bottom-warning d-flex align-items-center justify-content-center shadow-lg rounded-4" href="index.html">
          <div className="sidebar-brand-icon">
            <img src="https://dev.alfaprima.id/assets/img/logo/AP-W.png" alt="" width={54} />
          </div>
          <div className="sidebar-brand-text font-logo fs-5 fw-semibold">ALFA PRIMA</div>
        </Link>

        {user.nip !== "helper" ? (
          <>
            <hr className="border-0 my-2" />

            <SinggleMenu title="Dashboard" link="/dashboard" fa="fa-tachometer-alt"/>
            <SinggleMenu title="Alfa Prima" link="/alfa-prima" fa="fa-home" />
            {/* <SinggleMenu title="Profile" link="/profile" fa="fa-user" /> */}
            <SinggleMenu title="Slip Gaji" link="/slip-gaji" fa="fa-money-bill"/>
            <SinggleMenu title="KPI Anda" link="/kpi-show" fa="fa-cogs"/>

            <hr className="border-0 my-2" />

            <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
              Semua Staff
            </div>

            <hr className="sidebar-divider border-0 mt-2 mb-0" />

            <SinggleMenu title="Daily Report" link="/daily-report" fa="fa-briefcase"/>
            <MultipleMenu
              title="Form Pengajuan"
              fa="fa-envelope"
              links={[
                { title: "Lembur", link: "/lembur/show" },
                { title: "Cuti", link: "/cuti/show" },
                { title: "Ijin", link: "/ijin/show" },
                { title: "Ijin Absensi", link: "/absensi-pegawai/show" },
                {
                  title: "Meninggalkan Tugas",
                  link: "/meninggalkan-tugas/show",
                },
                { title: "Purchase Request", link: "/purchase-request" },
              ]}
            />

            <SinggleMenu title="Form Workorder" link="/workorder-show" fa="fa-dolly"/>
            <SinggleMenu title="Form Peminjaman Ruangan" link="/pengajuan-peminjaman-ruangan" fa="fa-home"/>
            <SinggleMenu title="Penilaian Kompetensi" link="/penilaian-kompetensi" fa="fa-heart"/>
            <SinggleMenu title="Berita terkini" link="/berita-terkini" fa="fa-border-all"/>
            <SinggleMenu title="Tutorial Sistem" link="/tutorial-sistem" fa="fa-laptop"/>
            <SinggleMenu title="Brosur Digital" link="/brosur-digital" fa="fa-book"/>
            
            <hr className="border-0 my-2" />

            <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
              ACC
            </div>

            <hr className="sidebar-divider border-0 mt-2 mb-0" />

            <MultipleMenu
              title="Approval"
              fa="fa-check-square"
              links={[
                { title: "Lembur", link: "/lembur/approval" },
                { title: "Cuti", link: "/cuti/approval" },
                { title: "Ijin", link: "/ijin/approval" },
                { title: "Ijin Absensi", link: "/absensi-pegawai/approval" },
                {
                  title: "Meninggalkan Tugas",
                  link: "/meninggalkan-tugas/approval",
                },
                {
                  title: "Purchase Request",
                  link: "/purchase-request/approval",
                },
              ]}
            />

            <SinggleMenu title="SOP" fa="fa-book" link="/sop" />

            <hr className="border-0 my-2" />

            <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
              Auditor
            </div>

            <hr className="sidebar-divider border-0 mt-2 mb-0" />

            <SinggleMenu
              title="Ceklist Monitoring"
              fa="fa-book"
              link="/ceklist-monitoring/auditor"
            />

            <SinggleMenu title="KPI" fa="fa-cogs" link="/kpi-auditor" />

            <hr className="border-0 my-2" />

            <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
              Auditee
            </div>

            <hr className="sidebar-divider border-0 mt-2 mb-0" />

            <SinggleMenu title="Ceklist Monitoring" fa="fa-book" link="/ceklist-monitoring/auditee"/>

            {/* It */}
            <It user={user} />
            {/* End It */}

            {/* Kacab */}
            <Kacab user={user} />
            {/* End Kacab */}

            {/* Akademik */}
            <Akademik user={user} />
            {/* End Akademik */}

            {/* PKK */}
            <Pkk user={user} />
            {/* End PKK */}

            {/* Aktivitas */}
            <Aktivitas user={user} />
            {/* End Aktifivitas */}

            {/* FO */}
            <Fo user={user} />
            {/* End FO */}

            {/* Humas & Pemasaran */}
            <HumasPemasaran user={user} />
            {/* End Humas & Pemasaran */}

            {/* KSU */}
            <Ksu user={user} />
            {/* End KSU */}

            {/* Desain & UPT */}
            {user.jabatan === "IT Programmer" ||
            user.jabatan === "Bagian Desain & Multimedia" ||
            user.jabatan === "Ka. Unit UPT" ||
            user.jabatan === "Bagian UPT" ||
            user.jabatan === "Dir. Akademik & Kemahasiswaan" ||
            user.jabatan === "Dir. Pusat Karir dan Kerjasama" ||
            user.jabatan === "Dir. Humas & Pemasaran" ||
            user.jabatan === "Dir. Keu & Sumber Daya" ||
            user.jabatan === "Penjaminan Mutu" ||
            user.jabatan === "Kepala Cabang Denpasar" ||
            user.jabatan === "Kepala Cabang Singaraja" ||
            user.jabatan === "Kepala Cabang Karangasem" ? (
              <>

                <hr className="border-0 my-2" />

                <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
                  Desain & UPT
                </div>

                <hr className="sidebar-divider border-0 mt-2 mb-0" />

                <SinggleMenu
                  title="Workorder"
                  fa="fa-tools"
                  link="/workorder"
                />

                <SinggleMenu
                  title="Peminjaman Ruangan"
                  link="/peminjaman-ruangan"
                  fa="fa-home"
                />
              </>
            ) : null}

            <Pjm user={user} />

            {/* Keuangan & SDM */}
            {user.jabatan === "IT Programmer" ||
            user.jabatan === "Kepala Cabang Denpasar" ||
            user.jabatan === "Kepala Cabang Singaraja" ||
            user.jabatan === "Kepala Cabang Karangasem" ||
            user.jabatan === "Ka.Bag. Keuangan & SDM" ||
            user.jabatan === "Dir. Keu & Sumber Daya" ||
            user.jabatan === "Bagian Akunting" ||
            user.jabatan === "Bagian Keu & Purchasing" ||
            user.jabatan === "Bagian Purchasing" ||
            user.jabatan === "Ka. Unit Resepsionis" ||
            user.jabatan === "Resepsionis" ? (
              <>
                <hr className="border-0 my-2" />

                <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
                  Keuangan & SDM
                </div>

                <hr className="sidebar-divider border-0 mt-2 mb-0" />

                <SinggleMenu
                  title="Mahasiswa"
                  fa="fa-users"
                  link="/mhs-keuangan"
                />
                <SinggleMenu
                  title="Pendaftar"
                  fa="fa-users"
                  link="/pendaftar-keuangan"
                />
              </>
            ) : null}

            {/* Master */}
            {user.jabatan === "IT Programmer" ? (
              <>
                <hr className="border-0 my-2" />

                <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
                  MASTER
                </div>

                <hr className="sidebar-divider border-0 mt-2 mb-0" />

                <SinggleMenu
                  title="Fix Absensi"
                  fa="fa-users"
                  link="/master-fix-absensi"
                />
                <SinggleMenu
                  title="Lembur All"
                  fa="fa-users"
                  link="/master-lembur"
                />
                <SinggleMenu
                  title="Generate Nilai Absensi"
                  fa="fa-users"
                  link="/master-generate-nilai-absensi"
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            {/* <SinggleMenu title="TAK" fa="fa-heart" link="/nilai-tak-akademik" />
            <MultipleMenu
              title="Aktivitas Mahasiswa"
              fa="fa-folder-open"
              links={[
                { title: "Program Kampus", link: "/program-kampus" },
                { title: "Lepasan", link: "/program-lepasan" },
              ]}
            /> */}

            <SinggleMenu
              title="Buku Perpustakaan"
              link="/buku-perpustakaan"
              fa="fa-book"
            />

            {/* <hr className="border-0 my-2" />

            <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
              Akademik
            </div>

            <hr className="sidebar-divider border-0 mt-2 mb-0" />

            <MultipleMenu
              title="Mahasiswa"
              fa="fa-users"
              links={[
                { title: "Data Mahasiswa", link: "/mhs-akademik" },
                { title: "Mahasiswa OJT", link: "/mhs-ojt" },
                { title: "Mahasiswa Nilai", link: "/mhs-nilai" },
              ]}
            />

            <MultipleMenu
              title="Internship"
              fa="fa-users"
              links={[
                { title: "Pendaftar", link: "/pendaftar-intership" },
                { title: "Peserta", link: "/peserta-intership" },
              ]}
            />

            <MultipleMenu
              title="Nilai"
              fa="fa-heart"
              links={[
                { header: "Nilai" },
                { title: "Umum", link: "/nilai-umum-akademik" },
                { title: "TAK", link: "/nilai-tak-akademik" },
                { title: "OJT", link: "/nilai-ojt-akademik" },
                { title: "Akhir", link: "/nilai-akhir-akademik" },
                { title: "Transkrip", link: "/nilai-transkrip-akademik" },
              ]}
            />

            <MultipleMenu
              title="Perkuliahan"
              fa="fa-calendar-alt"
              links={[
                { header: "Master Data" },
                { title: "Ruangan Kelas", link: "/ruangan-akademik" },
                {
                  title: "Kelompok Mata Kuliah",
                  link: "/kelompok-matkul-akademik",
                },
                { title: "Mata Kuliah", link: "/matkul-akademik" },
                { title: "Slot Waktu", link: "/jamkul-akademik" },
                { header: "Reguler" },
                { title: "Kelas Perkuliahan", link: "/kelas-akademik" },
              ]}
            /> */}
          </>
        )}

        <hr className="border-0 my-2" />

        <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
          More
        </div>

        <hr className="sidebar-divider border-0 mt-2 mb-0" />

        <li className="nav-item">
          <button
            className="nav-link btn py-0"
            onClick={() => {
              window.open("https://wa.me/6287773382283");
            }}
          >
            <i className="fas fa-fw fa-flag" />
            <span className="text-sm">Laporkan Bug</span>
          </button>
        </li>
      </ul>
    </>
  );
};

export default DashboardSidebar;