import React, { useRef, useState } from 'react';
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import SmallButton from '../../components/forms/SmallButton';
import { postBrosurDigital } from '../../api';
import FormInput from '../../components/forms/Input';
import { saAlert } from '../../helpers';

function BrosurDigitalCreate({fetchdata}) {
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [nomor, setNomor] = useState("");
  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(null);
  const formRef = useRef(null);

  const handleOpen = () => {
    setModal(true);
  }

  const handleClose = () => {
    if (formRef.current) {
        formRef.current.reset();
    }
    setModal(false);
    setSelectedFile(null);
    setPreview(null);
    setNomor("");
    setErrors({});
  }

  // Handle file change event
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById('brosurUpload').click();
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (selectedFile) {
        try {
            const formData = new FormData();
            formData.append('nomor', nomor);
            formData.append('image', selectedFile);
            const res = await postBrosurDigital(formData);
            if (res.data.status === 'success') {
                saAlert(res.data.status, res.data.msg);
                handleClose();
                fetchdata();
            }
        } catch (error) {
            setErrors(error.response.data.errors);
        }
    }
  };

  return (
    <>
      <button
        onClick={handleOpen}
        type="button"
        className="w-100 mb-3 btn btn-sm btn-primary-2 rounded-3 px-4"
      >
        <i className="fa fa-fw fa-arrow-up-from-bracket" /> Upload Brosur Digital
      </button>

      <LayoutModal
        size="lg"
        title="Upload Brosur Digital"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={formRef} onSubmit={handleUpload}>
            <div className="mb-3">
                <div className='row'>
                    <div className="col-lg-6">
                        {!preview ? (
                        <div style={{ height: '32rem', objectFit: 'contain' }}>
                            <div onClick={triggerFileInput} className='h-100 d-flex flex-column justify-content-center align-items-center rounded-3 box-image-2'>
                                <p className="mb-2 text-muted">Klik untuk memilih gambar.</p>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-secondary rounded-3 mb-3"
                                >
                                    <i className='fa fa-fw fa-image'/> Pilih Gambar
                                </button>
                                <p className='mb-0 text-sm text-muted'>Format: .jpg, .jpeg, .png</p>
                                <p className='mb-0 text-sm text-muted'>Ukuran: Max. 10 MB</p>
                            </div>
                        </div>
                        ) : (
                            <div className="w-100">
                            <img
                                src={preview}
                                alt="Brosur Preview"
                                className="w-100 img-fluid"
                                style={{ maxHeight: '32rem', objectFit: 'contain' }}
                            />
                            <SmallButton margin="py-1 px-2 mt-2" onClick={triggerFileInput} type="custom" buttonColor="dark" icon="pen" text="Ganti Gambar"/>
                            </div>
                        )}
                    </div>
                    <div className='col-lg-6'>
                        <FormInput
                            label="Nomor Urutan"
                            type="text"
                            placeholder="Masukkan Nomor"
                            onChange={(e) => setNomor(e.target.value)}
                            error={errors.nomor}
                            
                        />
                        <p className='mb-2'>Fungsi nomor untuk penanda bahwa brosur ini terletak pada urutan ke berapa.</p>
                        <p className='mb-0'>Contoh nomor: 1 (gambar ini akan paling depan.)</p>
                        {/* <h6 className='text-primary fw-semibold'>Informasi</h6>
                        <ul>
                            <li className='mb-2'>Input brosur dapat dilakukan secara satu per satu, tujuannya untuk memastikan bahwa gambar sesuai dengan urutannya.</li>
                            <li className='mb-2'>Maka dari itu pastikan untuk Input brosur dari paling depan hingga ke paling belakang.</li>
                            <li className='mb-2'>Format: .jpg, .jpeg, .png</li>
                            <li className='mb-2'>Ukuran: Max. 10 MB</li>
                        </ul> */}
                    </div>
                </div>
            <input
                aria-hidden="true"
                type="file"
                id="brosurUpload"
                accept="image/png, image/jpeg"
                className="d-none"
                onChange={handleFileChange}
            />
            </div>
            <div className='d-flex justify-content-center mt-4'>
                <NewSubmitButton onClose={handleClose} disabled={!selectedFile}/>
            </div>
        </form>
      </LayoutModal>
    </>
  );
}

export default BrosurDigitalCreate;
