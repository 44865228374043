import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { titleState } from '../../storage/pages';
import { fetchBrosurDigital } from '../../api';
import BrosurDigitalCreate from './BrosurDigitalCreate';
import { getOrigin } from '../../helpers';
import SmallButton from '../../components/forms/SmallButton';
import BrosurDigitalEdit from './BrosurDigitalEdit';
import BrosurDigitalDelete from './BrosurDigitalDelete';

function BrosurDigital() {

    const [title, setTitle] = useRecoilState(titleState);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchdata = async () => {
        setLoading(true);
        try {
            const res = await fetchBrosurDigital("all");
            if (res.data) {
                setItems(res.data);
            }
        } catch (error) {
            console.log(error.response);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setTitle("Brosur Digital");
        fetchdata();
        // let ignore = false;
        // if (!ignore) {
        //     fetchdata();
        // }
        // return () => { ignore = true };
    }, []);

  return (
    <div>
        <div className='row justify-content-end'>
            <div className='col-lg-3'>
                <BrosurDigitalCreate fetchdata={fetchdata}/>
            </div>
        </div>
        <div className='row p-0 m-0 g-2'>
            {!loading ? (
                items.brosur ? (
                    items.brosur.length > 0 ? (
                        items.brosur.map((b, index) => {
                            return (
                                <div className='col-lg-2 px-1' key={`${index}-${Date.now()}`}>
                                    <div className='bg-primary-4 text-gray-400 rounded-bottom-3'>
                                        <img src={`${getOrigin()}${items.images_path}/${b.id}.jpg`} alt={`${b.id}.jpg?${Date.now()}`} className='w-100 img-fluid'/>
                                        <div className='d-flex justify-content-between items-center p-2'>
                                            <p className='mb-0 text-sm'>No: <span className='fw-semibold text-warning'>{b.nomor}</span></p>
                                            <div className='d-flex justify-content-end items-center'>
                                                <BrosurDigitalEdit brosurUrl={items.images_path} brosur={b} fetchdata={fetchdata}/>
                                                <BrosurDigitalDelete fetchdata={fetchdata} brosur={b}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className='col-lg-12 px-1'>
                            <div className='d-flex justify-content-center items-center border rounded bg-silver' style={{paddingBottom: '8rem', paddingTop: '8rem'}}>
                                <p className='mb-0'>belum ada data brosur.</p>    
                            </div>
                        </div>
                    )
                ) : (
                    <div className='col-lg-12 px-1'>
                        <div className='d-flex justify-content-center items-center border rounded bg-silver' style={{paddingBottom: '8rem', paddingTop: '8rem'}}>
                            <p className='mb-0'>belum ada data brosur.</p>    
                        </div>
                    </div>
                )
            ) : (
                <>
                    <div className='col-lg-2 px-1'>
                        <div className='border rounded bg-silver fa-fade' style={{paddingBottom: '16rem'}}></div>
                    </div>
                    <div className='col-lg-2 px-1'>
                        <div className='border rounded bg-silver fa-fade' style={{paddingBottom: '16rem'}}></div>
                    </div>
                    <div className='col-lg-2 px-1'>
                        <div className='border rounded bg-silver fa-fade' style={{paddingBottom: '16rem'}}></div>
                    </div>
                    <div className='col-lg-2 px-1'>
                        <div className='border rounded bg-silver fa-fade' style={{paddingBottom: '16rem'}}></div>
                    </div>
                    <div className='col-lg-2 px-1'>
                        <div className='border rounded bg-silver fa-fade' style={{paddingBottom: '16rem'}}></div>
                    </div>
                    <div className='col-lg-2 px-1'>
                        <div className='border rounded bg-silver fa-fade' style={{paddingBottom: '16rem'}}></div>
                    </div>
                </>
            )}
        </div>
    </div>
  )
}

export default BrosurDigital