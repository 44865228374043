import React from "react";
import '../../print.css'
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";
import { getOrigin } from "../../helpers";
import { Helmet } from "react-helmet";


function PrintLayout({children, header, footer, customPadding, customFontSize}) {
  return (
    <>  
        <table className="print-font print-table">
            {header &&
                <thead>
                <tr>
                    <th className="print-th">
                        <div className="header-img p-3">
                            <img src="/assets/img/header-cover.jpg" alt="header Surat" className="w-100"/>
                        </div>
                    </th>
                </tr>
            </thead>
            }
            <tbody>
                <tr>
                    <td className={customPadding ? `${customPadding} print-td-custom` : "print-td"} style={{fontSize: customFontSize ? `${customFontSize}px` : '20px'}}>
                        {children}
                    </td>
                </tr>
            </tbody>
            {footer &&
                <tfoot>
                    <tr>
                        <th className="print-th">
                            <div className="footer-img p-3">
                                <img src="/assets/img/footer-cover.jpg" alt="footer Surat" className="w-100"/>
                            </div>
                        </th>
                    </tr>
                </tfoot>
            }
        </table>
    </>
  )
}

export const PrintHeader = (props) => {
    const { type = "potrait", fontSize = 17 } = props;

  const user = useRecoilValue(userState);

  const getImgWidth = () => {
    if (type === "potrait") {
      return "w-75";
    } else if (type === "landscape") {
      return "w-50";
    }
  };

  return (
    <>
      <div>
        <div className="row pb-3 mb-3 border-bottom border-silver">
          <div className="col-4 text-center">
            <img src={`${getOrigin()}/img/alfa-prima-logo-tulisan.png`} className="img-fluid" alt="alfa-prima-logo-tulisan.png" />
          </div>
          <div className="col-8 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <h2>ALFA PRIMA</h2>
              <p style={{fontSize: '16px'}}>
                {user.cabang === "pusat" && (
                  <>
                    Jl. Hayam Wuruk No.186, Sumerta Kelod, Kec. Denpasar Timur{" "}
                    <br /> Kota Denpasar, Bali 80239, Telepon 0361-232422 <br />{" "}
                    <i>www.alfaprima.id || Email: info@alfaprima.id</i>
                  </>
                )}

                {user.cabang === "denpasar" && (
                  <>
                    Jl. Hayam Wuruk No.186, Sumerta Kelod, Kec. Denpasar Timur{" "}
                    <br /> Kota Denpasar, Bali 80239, Telepon 0361-232422 <br />{" "}
                    <i>www.alfaprima.id || Email: info@alfaprima.id</i>
                  </>
                )}
                {user.cabang === "singaraja" && (
                  <>
                    Jalan Serma Karma No.88, Baktiseraga, Buleleng <br />
                    Kabupaten Buleleng, Bali 81119, Telepon 0362-3435453 <br />{" "}
                    <i>www.alfaprima.id || Email: info@alfaprima.id</i>
                  </>
                )}
                {user.cabang === "karangasem" && (
                  <>
                    Jalan Nenas, Subagan, Karangasem <br /> Kabupaten Karangasem, Bali
                    80811, Telepon 0363-2787499 <br />{" "}
                    <i>www.alfaprima.id || Email: info@alfaprima.id</i>
                  </>
                )}
              </p>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
    </>
  );
}

export const PrintButton = () => {

    const handlePrint = () => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            window.print();
        }, 500);
    }

    return (
        <div className="position-fixed d-print-none" style={{bottom: '1rem', right: '1rem'}}>
          <button onClick={handlePrint} type="button" className="btn btn-success-2 fs-6"><i className="fa fa-fw fa-print"/> Klik disini untuk Print</button>
        </div>
    )
}

export default PrintLayout