import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { showSkDosen } from "../../../api";
import PrintBtnPrint from "../../../components/print/BtnPrint";
import Header2022 from "../../../components/print/Header2022";
import PrintTtd from "../../../components/print/Ttd";
import { getCurrency, getHari, namaBulan } from "../../../helpers";
import PrintLayout, { PrintButton } from "../../../components/layouts/PrintLayout";

const DosenPrintLampiranSkDosen = () => {
  const { sk_dosen_id } = useParams();

  const [skDosen, setSkDosen] = useState({});
  const [dataKelas, setDataKelas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showSkDosen(sk_dosen_id);
        if (res.data && res.data.sk_dosen) {
          setSkDosen(res.data.sk_dosen);
          setDataKelas(JSON.parse(res.data.sk_dosen.data_kelas));
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    fetchData();
  }, [sk_dosen_id]);

  return (
    <>
      <PrintLayout header footer customPadding={"px-0"} customFontSize={16}>
        <table className="table table-sm table-borderless">
          <tbody>
            <tr>
              <td>Lampiran</td>
              <td className="text-center" style={{width: '10%'}}>:</td>
              <td>
                Keputusan Kepala Cabang Alfa Prima{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {skDosen.cabang}
                </span>
              </td>
            </tr>
            <tr>
              <td>Nomor</td>
              <td className="text-center" style={{width: '10%'}}>:</td>
              <td>{skDosen.nomor}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td className="text-center" style={{width: '10%'}}>:</td>
              <td>
                {skDosen.tanggal &&
                `${skDosen.tanggal.split("-")[2]} ${
                  namaBulan[parseInt(skDosen.tanggal.split("-")[1]) - 1]
                } ${skDosen.tanggal.split("-")[0]}`}
              </td>
            </tr>
            <tr>
              <td>Tentang</td>
              <td className="text-center" style={{width: '10%'}}>:</td>
              <td>
                Pengangkatan Tenaga Pengajar (dosen) Kontrak pada Lembaga Pendidikan
                dan Informatika Alfa Prima
              </td>
            </tr>
          </tbody>
        </table>
        
        <table className="table table-sm table-borderless mt-5">
          <tbody>
            <tr>
              <td className="py-0">Nama</td>
              <td className="py-0">:</td>
              <td className="py-0">{skDosen.nama_dosen}</td>
            </tr>
            <tr>
              <td className="py-0">Mata Kuliah</td>
              <td className="py-0">:</td>
              <td className="py-0">
              {dataKelas &&
                dataKelas
                  .filter((dk, i) => {
                    let namaMatkul = dataKelas.map(
                      (checkDK) => checkDK.matkul && checkDK.matkul.nama
                    );

                    if (dk.matkul && i === namaMatkul.indexOf(dk.matkul.nama)) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((dk, i) => {
                    return (
                      <React.Fragment key={i}>
                        {dk.matkul && dk.matkul.nama} <br />
                      </React.Fragment>
                    );
              })}
              </td>
            </tr>
            <tr>
              <td className="py-0">Honorarium</td>
              <td className="py-0">:</td>
              <td className="py-0">
              {dataKelas &&
            dataKelas.filter((dk) => {
              if (dk.matkul && parseInt(dk.matkul.sks) === 2) {
                return 1;
              }
              return 0;
            }).length > 0 && (
              <p className="m-0 p-0">
                Rp {getCurrency(skDosen.honor_dosen)} x 2 SKS = Rp{" "}
                {getCurrency(2 * parseInt(skDosen.honor_dosen))}/ Pertemuan
              </p>
            )}
          {dataKelas &&
            dataKelas.filter((dk) => {
              if (dk.matkul && parseInt(dk.matkul.sks) === 3) {
                return 1;
              }
              return 0;
            }).length > 0 && (
              <p className="m-0 p-0">
                Rp {getCurrency(skDosen.honor_dosen)} x 3 SKS = Rp{" "}
                {getCurrency(3 * parseInt(skDosen.honor_dosen))}/ Pertemuan
              </p>
            )}
              </td>
            </tr>
            <tr>
              <td className="py-0">Waktu Pertemuan</td>
              <td className="py-0">:</td>
              <td className="py-0">
              {dataKelas &&
            dataKelas.filter((dk) => {
              if (dk.matkul && parseInt(dk.matkul.sks) === 2) {
                return 1;
              }
              return 0;
            }).length > 0 && (
              <p className="m-0 p-0">
                2 SKS (1 Kali Pertemuan = 1 Jam 45 Menit)
              </p>
            )}
          {dataKelas &&
            dataKelas.filter((dk) => {
              if (dk.matkul && parseInt(dk.matkul.sks) === 3) {
                return 1;
              }
              return 0;
            }).length > 0 && (
              <p className="m-0 p-0">
                3 SKS (1 Kali Pertemuan = 2 Jam 45 Menit)
              </p>
            )}
              </td>
            </tr>
            <tr>
              <td className="py-0">Tgl. Mulai Perkuliahan</td>
              <td className="py-0">:</td>
              <td className="py-0">{skDosen.tanggal_mulai_perkuliahan}</td>
            </tr>
          </tbody>
        </table>

        <p className="fw-semibold">Jadwal :</p>
        <table className="table table-sm border">
            <thead>
              <tr>
                <th className="border text-center">No</th>
                <th className="border text-center">Kode Kelas</th>
                <th className="border text-center">Mata Kuliah</th>
                <th className="border text-center">SKS</th>
                <th className="border text-center">Ruang</th>
                <th className="border text-center">Jml Mhs</th>
                <th className="border text-center">Jadwal</th>
              </tr>
            </thead>
            <tbody>
              {dataKelas.length > 0 &&
                dataKelas.map((k, index) => {
                  return (
                    <tr key={index} className="border">
                      <td className="border text-center">{index + 1}</td>
                      <td className="border text-center">{k.kode}</td>
                      <td className="border">{k.matkul && k.matkul.nama}</td>
                      <td className="border text-center">
                        {k.matkul && k.matkul.sks}
                      </td>
                      <td className="border text-center">
                        {k.ruangan && k.ruangan.nama}
                      </td>
                      <td className="border text-center">
                        {k.mhs && k.mhs.length} Orang
                      </td>
                      <td className="border text-center">
                        {k.jamkul &&
                          `${getHari(k.jamkul.hari)} / ${k.jamkul.dari} - ${k.jamkul.sampai
                          }`}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="text-secondary text-center d-print-none">Anda dapat mengedit bagian TTD dibawah untuk menyesuaikan tampilan saat di Print.</div>

          <div className="row mt-5" contentEditable>
            <div className="col-6">
              <PrintTtd
                nama={skDosen.nama_kacab}
                jabatan="Kepala Cabang"
                footer={`NIP : ${skDosen.nip_kacab}`}
                cabang={skDosen.cabang}
                tanggal={
                  skDosen.tanggal &&
                  `${skDosen.tanggal.split("-")[2]} ${
                    namaBulan[parseInt(skDosen.tanggal.split("-")[1]) - 1]
                  } ${skDosen.tanggal.split("-")[0]}`
                }
              />
            </div>
            <div className="col-6">
              <PrintTtd
                nama={skDosen.nama_dosen}
                nip="Dosen"
                menyetujui={true}
                footer="Dosen"
              />
            </div>
          </div>
      {/* <div className="row mb-0">
          <div className="col-3 d-flex justify-content-between text-left">
            Lampiran <span>:</span>
          </div>
          <div className="col-9">
            Keputusan Kepala Cabang Alfa Prima{" "}
            <span style={{ textTransform: "capitalize" }}>
              {skDosen.cabang}
            </span>
          </div>
        </div> */}

        {/* <div className="row mb-0">
          <div className="col-3 d-flex justify-content-between text-left">
            Nomor <span>:</span>
          </div>
          <div className="col-9">{skDosen.nomor}</div>
        </div> */}

        {/* <div className="row mb-0">
          <div className="col-3 d-flex justify-content-between text-left">
            Tanggal <span>:</span>
          </div>
          <div className="col-9">
            {skDosen.tanggal &&
              `${skDosen.tanggal.split("-")[2]} ${
                namaBulan[parseInt(skDosen.tanggal.split("-")[1]) - 1]
              } ${skDosen.tanggal.split("-")[0]}`}
          </div>
        </div> */}

        {/* <div className="row">
          <div className="col-3 d-flex justify-content-between text-left">
            Tentang <span>:</span>
          </div>
          <div className="col-9">
            Pengangkatan Tenaga Pengajar (dosen) Kontrak pada Lembaga Pendidikan
            dan Informatika Alfa Prima
          </div>
        </div> */}

        {/* <hr /> */}

        {/* <div className="row mb-0">
          <div className="col-3 d-flex justify-content-between text-left">
            (1) Nama <span>:</span>
          </div>
          <div className="col-9">{skDosen.nama_dosen}</div>
        </div> */}
        {/* <div className="row mb-0">
          <div className="col-3 d-flex justify-content-between text-left">
            (2) Mata Kuliah <span>:</span>
          </div>
          <div className="col-9">
            {dataKelas &&
              dataKelas
                .filter((dk, i) => {
                  let namaMatkul = dataKelas.map(
                    (checkDK) => checkDK.matkul && checkDK.matkul.nama
                  );

                  if (dk.matkul && i === namaMatkul.indexOf(dk.matkul.nama)) {
                    return 1;
                  }
                  return 0;
                })
                .map((dk, i) => {
                  return (
                    <React.Fragment key={i}>
                      {dk.matkul && dk.matkul.nama} <br />
                    </React.Fragment>
                  );
                })}
          </div>
        </div> */}
        {/* <div className="row mb-0">
          <div className="col-3 d-flex justify-content-between text-left">
            (3) Honorarium <span>:</span>
          </div>
          <div className="col-9">
            {dataKelas &&
              dataKelas.filter((dk) => {
                if (dk.matkul && parseInt(dk.matkul.sks) === 2) {
                  return 1;
                }
                return 0;
              }).length > 0 && (
                <p className="m-0 p-0">
                  Rp {getCurrency(skDosen.honor_dosen)} x 2 SKS = Rp{" "}
                  {getCurrency(2 * parseInt(skDosen.honor_dosen))}/ Pertemuan
                </p>
              )}
            {dataKelas &&
              dataKelas.filter((dk) => {
                if (dk.matkul && parseInt(dk.matkul.sks) === 3) {
                  return 1;
                }
                return 0;
              }).length > 0 && (
                <p className="m-0 p-0">
                  Rp {getCurrency(skDosen.honor_dosen)} x 3 SKS = Rp{" "}
                  {getCurrency(3 * parseInt(skDosen.honor_dosen))}/ Pertemuan
                </p>
              )}
          </div>
        </div> */}
        {/* <div className="row mb-0">
          <div className="col-3 d-flex justify-content-between text-left">
            (4) Waktu Pertemuan <span>:</span>
          </div>
          <div className="col-9">
            {dataKelas &&
              dataKelas.filter((dk) => {
                if (dk.matkul && parseInt(dk.matkul.sks) === 2) {
                  return 1;
                }
                return 0;
              }).length > 0 && (
                <p className="m-0 p-0">
                  2 SKS (1 Kali Pertemuan = 1 Jam 45 Menit)
                </p>
              )}
            {dataKelas &&
              dataKelas.filter((dk) => {
                if (dk.matkul && parseInt(dk.matkul.sks) === 3) {
                  return 1;
                }
                return 0;
              }).length > 0 && (
                <p className="m-0 p-0">
                  3 SKS (1 Kali Pertemuan = 2 Jam 45 Menit)
                </p>
              )}
          </div>
        </div> */}
        {/* <div className="row mb-0">
          <div className="col-3 d-flex justify-content-between text-left">
            (5) Tgl. Mulai Perkuliahan <span>:</span>
          </div>
          <div className="col-9">{skDosen.tanggal_mulai_perkuliahan}</div>
        </div> */}
        {/* <div className="row mb-5 pb-5">
          <div className="col-3 d-flex justify-content-between text-left">
            (6) Jadwal <span>:</span>
          </div>
          <div className="col-12 mt-3">
            <table className="table table-sm border">
              <thead>
                <tr>
                  <th className="border text-center">No</th>
                  <th className="border text-center">Kode Kelas</th>
                  <th className="border text-center">Mata Kuliah</th>
                  <th className="border text-center">SKS</th>
                  <th className="border text-center">Ruang</th>
                  <th className="border text-center">Jml Mhs</th>
                  <th className="border text-center">Jadwal</th>
                </tr>
              </thead>
              <tbody>
                {dataKelas.length > 0 &&
                  dataKelas.map((k, index) => {
                    return (
                      <tr key={index} className="border">
                        <td className="border text-center">{index + 1}</td>
                        <td className="border text-center">{k.kode}</td>
                        <td className="border">{k.matkul && k.matkul.nama}</td>
                        <td className="border text-center">
                          {k.matkul && k.matkul.sks}
                        </td>
                        <td className="border text-center">
                          {k.ruangan && k.ruangan.nama}
                        </td>
                        <td className="border text-center">
                          {k.mhs && k.mhs.length} Orang
                        </td>
                        <td className="border text-center">
                          {k.jamkul &&
                            `${getHari(k.jamkul.hari)} / ${k.jamkul.dari} - ${
                              k.jamkul.sampai
                            }`}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div> */}

        {/* <div className="row mb-5">
          <div className="col-6">
            <PrintTtd
              nama={skDosen.nama_kacab}
              jabatan="Kepala Cabang"
              footer={`NIP : ${skDosen.nip_kacab}`}
              cabang={skDosen.cabang}
              tanggal={
                skDosen.tanggal &&
                `${skDosen.tanggal.split("-")[2]} ${
                  namaBulan[parseInt(skDosen.tanggal.split("-")[1]) - 1]
                } ${skDosen.tanggal.split("-")[0]}`
              }
            />
          </div>
          <div className="col-6">
            <PrintTtd
              nama={skDosen.nama_dosen}
              nip="Dosen"
              menyetujui={true}
              footer="Dosen"
            />
          </div>
        </div> */}
        <PrintButton/>
      </PrintLayout>
    </>
  );
};

export default DosenPrintLampiranSkDosen;
