import React, { useRef, useState } from 'react';
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import SmallButton from '../../components/forms/SmallButton';
import { postBrosurDigital } from '../../api';
import FormInput from '../../components/forms/Input';
import { getOrigin, saAlert } from '../../helpers';

function BrosurDigitalEdit({ fetchdata, brosur, brosurUrl }) {
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [nomor, setNomor] = useState("");
  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(null);
  const [fileKey, setFileKey] = useState(Date.now());

  const formRef = useRef(null);

  const handleOpen = () => {
    setModal(true);
    setNomor(brosur.nomor);
    // Load existing image with cache-busting query string
    setPreview(`${getOrigin()}${brosurUrl}/${brosur.id}.jpg?${Date.now()}`);
  }

  const handleClose = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setModal(false);
    setSelectedFile(null);
    setPreview(null);
    setNomor("");
    setErrors({});
    setFileKey(Date.now());
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById(`brosurUploadEdit-${brosur.id}`);
    fileInput.value = '';
    fileInput.click();
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('brosur_id', brosur.id);
        formData.append('nomor', nomor);
        formData.append('image', selectedFile);
        const res = await postBrosurDigital(formData);
        if (res.data.status === 'success') {
          saAlert(res.data.status, res.data.msg);
          handleClose();
          fetchdata();
          // Update preview with new image after successful upload (cache-busting)
          setPreview(`${getOrigin()}${brosurUrl}/${brosur.id}.jpg?${Date.now()}`);
        }
      } catch (error) {
        setErrors(error.response.data.errors);
      }
    }
  };

  return (
    <>
      <SmallButton onClick={handleOpen} margin="me-1" type="edit" text="Edit" />

      <LayoutModal
        size="lg"
        title="Edit Brosur Digital"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={formRef} onSubmit={handleUpload}>
          <div className="mb-3">
            <div className="row">
              <div className="col-lg-6">
                {!preview ? (
                  <div style={{ height: '32rem', objectFit: 'contain' }}>
                    <div onClick={triggerFileInput} className='h-100 d-flex flex-column justify-content-center align-items-center rounded-3 box-image-2'>
                      <p className="mb-2 text-muted">Klik untuk memilih gambar.</p>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary rounded-3 mb-3"
                      >
                        <i className='fa fa-fw fa-image' /> Pilih Gambar
                      </button>
                      <p className='mb-0 text-sm text-muted'>Format: .jpg, .jpeg, .png</p>
                      <p className='mb-0 text-sm text-muted'>Ukuran: Max. 10 MB</p>
                    </div>
                  </div>
                ) : (
                  <div className="w-100">
                    <img
                      src={preview}
                      alt="Brosur Preview"
                      className="w-100 img-fluid"
                      style={{ maxHeight: '32rem', objectFit: 'contain' }}
                    />
                    <SmallButton margin="py-1 px-2 mt-2" onClick={triggerFileInput} type="custom" buttonColor="dark" icon="pen" text="Ganti Gambar" />
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <FormInput
                  label="Nomor Urutan"
                  type="text"
                  placeholder="Masukkan Nomor"
                  value={nomor}
                  onChange={(e) => setNomor(e.target.value)}
                  error={errors.nomor}
                />
                <p className='mb-2'>Fungsi nomor untuk penanda bahwa brosur ini terletak pada urutan ke berapa.</p>
                <p className='mb-0'>Contoh nomor: 1 (gambar ini akan paling depan.)</p>
              </div>
            </div>
            <input
              aria-hidden="true"
              type="file"
              id={`brosurUploadEdit-${brosur.id}`}
              accept="image/png, image/jpeg"
              className="d-none"
              key={fileKey}
              onChange={handleFileChange}
            />
          </div>
          <div className='d-flex justify-content-center mt-4'>
            <NewSubmitButton onClose={handleClose} />
          </div>
        </form>
      </LayoutModal>
    </>
  );
}

export default BrosurDigitalEdit;
