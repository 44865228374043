import React from 'react'
import SmallButton from '../../components/forms/SmallButton';
import { saAlert, saConfirm } from '../../helpers';
import { deleteBrosurDigital } from '../../api';

function BrosurDigitalDelete({fetchdata, brosur}) {

    const handleDelete = (id) => {
        saConfirm('warning', 'Yakin hapus arus kas ini?').then(async (res) => {
            if (res.isConfirmed) {
                try {
                    const res = await deleteBrosurDigital(id);
                    fetchdata();
                    saAlert(res.data.status, res.data.msg);
                } catch (error) {
                    console.log(error);
                }
            }
        })
    }

  return (
    <SmallButton onClick={() => handleDelete(brosur.id)} type='delete' text="Hapus"/>
  )
}

export default BrosurDigitalDelete